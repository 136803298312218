import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DataManagement } from '../services/dataManagement';
import { Router } from '@angular/router';
import { Cookies, Cliente, Plantilla, Evento } from '../models/data.model';
import { GrupoEventos } from 'app/models/others.model';
import { FirebaseStorageService } from './firebase-storage.service';
import { environment } from 'environments/environment';

@Injectable()
export class GlobalService {
  checkService(service: string) {
      for (let i=0; i < this.services.length; i++) {
        if ((this.services[i].tipo === service || this.services[i].tipo === 'all') && this.services[i].activo ) {
          return(true);
        }
      }
      return (false)
  }

  //Variables
  cliente: Cliente;
  cookies: Cookies;
  gruposEventos: GrupoEventos[];
  eventosPago: Evento[];
  refrescoDeDatos: NodeJS.Timeout;
    campaigns: any;
    callActual: any;
    campaignActual: any = null;
    fechaActual: any;
    resumen: any;
    fechaFinActual: any;
    datosCalls: any = [];
    pageIndex: number = 0;
    pageSize: number = 10;
    selectedCallId: any;
    clienteAivoz: any;
    campaignAivoz: any;
    Admin: boolean = false;
    discador: any = null;
    filtroTipificacion: string = '';
    services: any;
   

  constructor(
    private cookieService: CookieService,
    private dm: DataManagement,
    private router: Router,
    private firebaseStorageService: FirebaseStorageService,
  ) {
    this.initialize();
  }

  private initialize() {
    //Inicializamos variables
    this.cliente = null;
    this.cookies = new Cookies();
    this.gruposEventos = [];
    this.callActual = null;
    this.campaignActual = null;
    this.campaigns = null;
    this.cliente = null;
    this.datosCalls = null;
    this.fechaActual = null;
    this.fechaFinActual = null;
    this.datosCalls = null;
    this.clienteAivoz = null;
    this.campaignAivoz = null;

  }

  cargarDatos(cookies: any) {
    return cookies;
  }

  /**
   * Método para leer las cookies y cargar todos los datos de la aplicación.
   * @param forzarCarga Opcional, "true" para forzar la carga de datos en la lectura.
   */
  leerCookies(forzarCarga?: boolean) {
    return new Promise<boolean>((resolve, reject) => {
      //Comprobamos si existen las cookies, se comprueba cadena "null" ya que en algunos navegadores no funciona bien el borrado.
      //Para solucionarlo lo que hacemos es setear la variable a null (pero por defecto en las cookies todo son strings)
      if (this.cookieService.get('dashSmartBiometrikData') && this.cookieService.get('dashSmartBiometrikData') != "null") {

        //En caso de existir, cogemos todos los datos y los guardamos en la variable global.
        this.cookies = JSON.parse(this.cookieService.get('dashSmartBiometrikData'));

        //Comprobamos que las cookies básicas existen, en caso contrario devolvemos false.
        if (!this.cookies) {
          resolve(false);
        } else if (!this.cookies.cliente) {
          resolve(false);
        } else {
          // En caso de querer actualizar los datos del cliente, forzamos la carga.
          if (!this.cliente || forzarCarga) {
            //Cargamos todos los datos.
            this.cargarDatos(this.cookies).then(res => {
              //Comprobamos que los datos se han cargado correctamente.
              if (res) {
                resolve(true);
              } else {
                resolve(false);
              }
            }).catch(error => {
              //console.log("error al cargar datos: ", error);
              resolve(false);
            });
          } else {
            resolve(true);
          }
        }
      } else {
        resolve(false);
      }
    });
  }

  guardarCookies(cookies: Cookies) {
    this.cookies = cookies;
    if (environment.production) {
      this.cookieService.set('dashSmartBiometrikData', JSON.stringify(cookies), 3, '/', ".smartbiometrik.com", true, "Strict");
    } else {
      this.cookieService.set('dashSmartBiometrikData', JSON.stringify(cookies), 3, '/', "", false, "Strict");
    }
  }

  /**
   * Método de carga de datos en la aplicación
   * @param cookies Elemento de tipo "Cookies"
   */

  incluyeEventosEnGrupo() {
    this.gruposEventos = [];
    this.eventosPago = [];
    this.cliente.eventos.forEach((evento, index) => {
      console.log('evento: ', evento, index)
      if (evento.tipo == "pago") {
        evento.id = index+1;
        this.eventosPago.push(evento);
      } else {
        let grupoEncontrado =  this.gruposEventos.find(grupoEventos => {
          return (grupoEventos.title == evento.titulo)
            && (grupoEventos.template._id == evento.idPlantilla)
            && ((evento.tipo == "multifirma" && evento.contadorfirmas == 1) || (evento.tipo == "firmaRapida" && evento.contadorfirmas == 20));
        });
        if (grupoEncontrado) {
          console.log('grupo evento: ', evento, index)
          grupoEncontrado.addNewEvent(evento);
        } else {
          console.log('new grupo evento: ', evento, index)
          let newGrupoEventos: GrupoEventos = new GrupoEventos(evento, this.gruposEventos.length + 1);
          let plantillaEvento = this.cliente.plantillas.find(plantilla => {
            return plantilla._id == evento.idPlantilla;
          });
          newGrupoEventos.template = plantillaEvento || new Plantilla();
          this.gruposEventos.push(newGrupoEventos);
          console.log('GRUPO EVENTOS: ', this.gruposEventos)
        }
      }
    });
  }

  /**
   * Busca el grupo de eventos que contiene al evento con el id especificado.
   * 
   * @param idEvento Id del evento que buscamos dentro del grupo.
   */
  obtenerGrupoDeEventos(idEvento: string): GrupoEventos {
    let grupoEventos: GrupoEventos = this.gruposEventos.find(grupo => {
      let evento = grupo.events.find(evento => {
        return evento._id == idEvento;
      });
      return evento ? true : false;
    });
    return grupoEventos ? grupoEventos : null;
  }

  /**
   * Método para subir archivos en Firebase
   * 
   * @param file Contiene el fichero subido. Si no se recibe, no sube ningún fichero.
   * @param ruta Indica la carpeta donde se guardará el fichero
   * @param nombre Indica el nombre del archivo
   * @param extension Indica la extension del archivo
   * La ruta donde se guarda es: "[URL_FIREBASE]/[ruta]/nombre"
   */
  subirArchivo(file: any, ruta: string, nombre: string, extension: string) {

    return new Promise<any>((resolve, reject) => {

      if (file) {

        let fileName = "";
        fileName = nombre + "." + extension;

        this.firebaseStorageService.subirArchivo((ruta ? ruta + "/" : "") + fileName, file).then(res => {
          //console.log("res subir archivo", res);
          if (res.state == "success") {
            this.obtenerEnlaceArchivo((ruta ? ruta + "/" : "") + fileName).then(fichero => {
              let result = {
                urlRelativa: (ruta ? ruta + "/" : "") + fileName,
                urlCompleta: fichero
              }
              resolve(result);
            }).catch(error => {
              //console.log("error subir archivo: ", error);
              reject(res);
            });
          } else {
            //console.log("error subir archivo: ", res);
            reject(res);
          }
        }).catch(error => {
          //console.log("error subir archivo: ", error);
          reject(error);
        });

      } else {

        resolve("salto")

      }
    });
  }

  /**
   * Método para eliminar archivos en Firebase
   * 
   * @param enlace Ruta, nombre y extensión del archivo que queremos borrar.
   */
  borrarArchivo(enlace: string) {

    return new Promise<boolean>((resolve, reject) => {

      if (enlace) {

        this.firebaseStorageService.borrarArchivo(enlace).subscribe(fichero => {
          resolve(true);
        }, err => {
          resolve(false);
        });

      } else {

        reject("No hay archivo");

      }
    });
  }


  /**
   * Método que, dado una ruta relativa del archivo, nos devuelve la ruta completa al mismo.
   * 
   * @param enlace Ruta, nombre y extensión del archivo que queremos obtener.
   */
  obtenerEnlaceArchivo(enlace: string) {
    return new Promise<boolean>((resolve, reject) => {

      this.firebaseStorageService.descargarArchivo(enlace).subscribe(fichero => {
        resolve(fichero);
      }, error => {
        reject(error);
      })
    });
  }

  generarCadenaAleatoria(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  iniciarSesion(cliente, cookies) {
    this.cookies = cookies;
    this.cliente = cliente;
    this.incluyeEventosEnGrupo();
    if (environment.production) {
      this.cookieService.set('dashSmartBiometrikData', JSON.stringify(cookies), 3, '/', ".smartbiometrik.com", true, "Strict");
    } else {
      this.cookieService.set('dashSmartBiometrikData', JSON.stringify(cookies), 3, '/', "", false, "Strict");
    }
  }

  cerrarSesion() {
    // Seteamos a null, ya que el borrado no funciona en todos los navegadores.
    // Ojo, la cookie será el string "null", y no el valor nulo.
  
    
    this.initialize();
    setTimeout(() => {
        this.Admin = false;
        this.router.navigateByUrl("/pages/auth/login");
     
    }, 500);
  }

  // FUNCIONES PARA VERIFICACION


}